// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "v_fQ d_fQ d_by d_bG d_bH";
export var navbarDividedRight = "v_fR d_fR d_by d_bH";
export var menuDesign6 = "v_sY d_fM d_by d_dv d_bN d_bH";
export var menuDesign7 = "v_sZ d_fM d_by d_dv d_bN d_bH";
export var menuRight = "v_s0 d_fM d_by d_dv d_bN d_bH";
export var menuLeft = "v_s1 d_fM d_by d_dv d_bN d_bH";
export var menuCenter = "v_s2 d_fN d_fM d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "v_qp d_fN d_fM d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "v_qz d_fP d_fM d_by d_dv d_bN d_bH";
export var isBurger = "v_s3";
export var navbarItem = "v_qq d_bw";
export var navbarLogoItemWrapper = "v_fY d_fY d_bB d_bN";
export var burgerToggleVisibleOpen = "v_s4 d_gd d_bx d_Y d_br";
export var burgerToggleVisible = "v_s5 d_gd d_bx d_Y d_br";
export var burgerToggle = "v_s6 d_gd d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "v_s7 d_gd d_bx d_Y d_br";
export var burgerInput = "v_s8 d_f2 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "v_s9 d_f3 d_v d_G";
export var burgerLine = "v_tb d_f1";
export var burgerMenuDesign6 = "v_tc d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "v_td d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "v_tf d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "v_tg d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "v_th d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "v_tj d_f9 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "v_tk d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var secondary = "v_tl d_bB d_bN";
export var staticBurger = "v_tm";
export var menu = "v_tn";
export var navbarDividedLogo = "v_tp";
export var nav = "v_tq";