// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_r4 d_fn d_bF d_dt";
export var alignLeft = "r_r5 d_fn d_bF d_dt";
export var alignDiscCenter = "r_r6 d_fp d_bC d_dv";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignDiscRight = "r_r7 d_fq d_bG d_dw";
export var alignRight = "r_r8 d_fq d_bG d_dw";
export var footerContainer = "r_r9 d_dV d_bV";
export var footerContainerFull = "r_sb d_dS d_bV";
export var footerHeader = "r_kf d_kf";
export var footerTextWrapper = "r_sc d_v";
export var footerDisclaimerWrapper = "r_km d_km d_cj";
export var badgeWrapper = "r_sd d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "r_sf undefined";
export var footerDisclaimerLeft = "r_sg undefined";
export var verticalTop = "r_sh d_by d_bH d_bM d_bK";
export var firstWide = "r_sj";
export var disclaimer = "r_sk d_by d_bH";
export var socialDisclaimer = "r_sl";
export var left = "r_sm";
export var wide = "r_sn d_cx";
export var right = "r_sp d_bJ";
export var line = "r_fj d_fk d_ct";
export var badgeDisclaimer = "r_sq d_bB d_bN d_bH";
export var badgeContainer = "r_sr d_by d_bG d_bN";
export var badge = "r_ss";
export var padding = "r_st d_c6";
export var end = "r_sv d_bG";
export var linkWrapper = "r_sw d_dz";
export var link = "r_mC d_dz";
export var colWrapper = "r_sx d_cw";
export var consent = "r_f d_f d_bB d_bN";
export var media = "r_sy d_bw d_dx";
export var itemRight = "r_sz";
export var itemLeft = "r_sB";
export var itemCenter = "r_sC";
export var exceptionWeight = "r_sD q_rg";