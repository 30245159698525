// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_sF d_gv d_cr";
export var heroHeaderCenter = "s_gw d_gw d_cr d_dv";
export var heroHeaderRight = "s_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "s_sG d_gr d_cv";
export var heroParagraphCenter = "s_gs d_gs d_cv d_dv";
export var heroParagraphRight = "s_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "s_sH d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_sJ d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_sK d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_sL d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_sM d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_rF q_rF";
export var heroExceptionNormal = "s_rG q_rG";
export var heroExceptionLarge = "s_rH q_rH";
export var Title1Small = "s_rk q_rk q_qQ q_qR";
export var Title1Normal = "s_rl q_rl q_qQ q_qS";
export var Title1Large = "s_rm q_rm q_qQ q_qT";
export var BodySmall = "s_rv q_rv q_qQ q_q7";
export var BodyNormal = "s_rw q_rw q_qQ q_q8";
export var BodyLarge = "s_rx q_rx q_qQ q_q9";